<template>
  <div class="home-page">
    <myHead></myHead>
    <img class="productobg" src="../assets/productobg.png" alt="">
    <div class="box">
      <img class="title" src="../assets/hometitle1.png" alt="">
      <div class="infobox">
        Nuestro grupo ofrece múltiples productos de préstamos personales a corto plazo que van desde 91 días hasta 365 días. En el campo financiero, estamos comprometidos a brindar soluciones de servicios financieros de primer nivel a los usuarios en todo el mundo. Con la ayuda de publicidad personalizada en Google y Meta (anteriormente Facebook), análisis de datos, política de control de riesgos y soluciones de pago integrales, hacemos que los préstamos personales sean instantáneos y estén disponibles para todos los ciudadanos legales.
      </div>
    </div>
    <myFoot />
  </div>
</template>

<script>
import myHead from "@/components/header/header";
import myFoot from "@/components/footer/footer";
export default {
  data() {
    return {

    }
  },
  created() {
  },
  methods: {
  },
  components: {
    myHead, myFoot
  }
};
</script>

<style lang="scss" scoped>
.home-page {
  width: 100%;
  padding-top: 100px;

  .productobg {
    width: 100%;
    background-color: #000;
    vertical-align: middle;
  }
  .box {
    padding: 40px 80px;
    background-color: #000;

    .title {
      width: 800px;
      display: block;
      margin: 0 auto;
    }
    .infobox {
      width: 100%;
      padding: 40px;
      box-sizing: border-box;
      background: url('../assets/boxbg.png') no-repeat;
      background-size: cover;
      margin-top: 40px;
      font-family: DINPro, DINPro;
      font-weight: 400;
      font-size: 20px;
      color: #2C2C2C;
      line-height: 30px;
      text-align: justify;
      font-style: normal;
    }
  }
}
</style>
