<template>
  <div class="home-page">
    <myHead></myHead>
    <img class="productobg" src="../assets/sobrebg.png" alt="">
    <div class="box">
      <img class="title" src="../assets/sobretitle.png" alt="">
      <div class="infobox">
        es una plataforma que proporciona préstamos en línea rápidos, accesibles y seguros. Regístrese en segundos, solicite un préstamo y reciba su efectivo en minutos. Vemos el mundo de otra manera. Nos impulsa una creencia fundamental en las personas y trabajamos juntos para demostrar su potencial. Tomamos riesgos que otros no tomarán, poniendo más poder en manos de nuestros clientes.
      </div>
    </div>
    <myFoot />
  </div>
</template>

<script>
import myHead from "@/components/header/header";
import myFoot from "@/components/footer/footer";
export default {
  data() {
    return {

    }
  },
  created() {
  },
  methods: {
  },
  components: {
    myHead, myFoot
  }
};
</script>

<style lang="scss" scoped>
.home-page {
  width: 100%;
  padding-top: 100px;

  .productobg {
    width: 100%;
    background-color: #000;
    vertical-align: middle;
  }
  .box {
    padding: 40px 80px;
    background-color: #000;

    .title {
      width: 800px;
      display: block;
      margin: 0 auto;
    }
    .infobox {
      width: 100%;
      padding: 40px;
      box-sizing: border-box;
      background: url('../assets/boxbg.png') no-repeat;
      background-size: cover;
      margin-top: 40px;
      font-family: DINPro, DINPro;
      font-weight: 400;
      font-size: 20px;
      color: #2C2C2C;
      line-height: 30px;
      text-align: justify;
      font-style: normal;
    }
  }
}
</style>
