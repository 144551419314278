<template>
  <div class="footer-com">

    <div class="box3">
      <img src="../../assets/homeimg3.png" alt="">
      <div class="top">
        <div class="title">PFI CENTER S.A.S.</div>
        <div class="info">Jorge Washington & Avenida 10 de Agosto, Quito, Pichincha</div>
      </div>
      <div class="flex">
        <div class="item">
          <img src="../../assets/whatsapp.png" alt="">
          <div class="right">
            <div class="tit">WhatsApp</div>
            <div class="txt">+593 0990127918</div>
          </div>
        </div>
        <div class="item">
          <img src="../../assets/phone.png" alt="">
          <div class="right">
            <div class="tit">Teléfono</div>
            <div class="txt">+593 0990127918</div>
          </div>
        </div>
        <div class="item">
          <img src="../../assets/phone.png" alt="">
          <div class="right">
            <div class="tit">Correo</div>
            <div class="txt">contacto@pficentresas.com</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      PFI CENTER S.A.S. es una empresa registrada en Ecuador.
      El préstamo se aprobará si se cumplen los requisitos de las condiciones crediticias evaluadas previa solicitud a través de la plataforma web
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  created() {
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
.footer-com {
  text-align: center;

  .box3 {
    position: relative;
    margin-bottom: 40px;
    > img {
      width: 100%;
    }
    .top {
      position: absolute;
      left: 0px;
      right: 0px;
      top: 50px;
      color: #fff;
      .title {
        font-family: Geometric415BT, Geometric415BT;
        font-weight: normal;
        font-size: 80px;
        color: #FFFFFF;
        line-height: 132px;
        text-align: center;
        font-style: normal;
      }
      .info {
        font-family: DINPro, DINPro;
        font-weight: 400;
        font-size: 30px;
        color: #FFFFFF;
        line-height: 61px;
        text-align: center;
        font-style: normal;
        text-transform: uppercase;
        margin-top: 30px;
      }
    }
    .flex {
      width: 100%;
      position: absolute;
      left: 0px;
      bottom: 0px;
      display: flex;

      .item {
        flex: 1;
        display: flex;
        padding: 30px;
        &:nth-child(1) {
          background-color: #242424;
        }
        &:nth-child(2) {
          background-color: #2F2F2F;
        }
        &:nth-child(3) {
          background-color: #434343;
        }

        >img {
          width: 80px;
        }
        .right {
          margin-left: 14px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          .tit {
            color: #8b8b8b;
          }
          .txt {
            color: #fff;
          }
        }
      }
    }
  }
  .content {
    width: 100%;
    box-sizing: border-box;
    padding: 40px 120px;
    font-family: DINPro, DINPro;
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 31px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    background: #242424;
  }
}
</style>