<template>
  <div class="home-page">
    <myHead></myHead>
    <img class="homebg" src="../assets/homebg.png" alt="">
    <div class="box">
      <img class="title" src="../assets/hometitle1.png" alt="">
      <img class="img" src="../assets/homeimg1.png" alt="">
    </div>
    <div class="box box2">
      <img class="title" src="../assets/hometitle2.png" alt="">
      <img class="img" src="../assets/homeimg2.png" alt="">
    </div>
    <myFoot />
  </div>
</template>

<script>
import myHead from "@/components/header/header";
import myFoot from "@/components/footer/footer";
export default {
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
  },
  components: {
    myHead, myFoot
  }
};
</script>

<style lang="scss" scoped>
  .home-page {
    width: 100%;
    padding-top: 100px;

    .homebg {
      width: 100%;
      vertical-align: middle;
      background: #000;
      user-select: none;
    }
    .box {
      padding: 40px 80px;
      background-color: #000;
      &.box2 {
        background: linear-gradient(#000 50%, #fff 50%);
      }

      .title {
        width: 800px;
        display: block;
        margin: 0 auto;
      }
      .img {
        width: 100%;
        display: block;
        margin-top: 40px;
        user-select: none;
      }
    }
  }
</style>
