<template>
  <div class="header-com">
    <div class="wrap">
      <div class="left-area">
        <img class="img" src="../../assets/logo.png" width="400px;height:80px;" />
      </div>
      <div class="middle-area"></div>
      <div class="right-area">
        <ul class="list">
          <router-link tag="li" class="item" to="/inicio">Inicio</router-link>
          <router-link tag="li" class="item" to="/producto">Producto</router-link>
          <router-link tag="li" class="item" to="/sobre-nosotros">Sobre nosotros</router-link>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import minxinLocalStorage from "../../common/minxinLocalStorage.js";
export default {
  mixins: [minxinLocalStorage],
  data() {
    return {

    };
  },
  created() {

  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
.header-com {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 999;

  .wrap {
    display: flex;
    height: 100px;
    line-height: 100px;
    background: #000000;

    .left-area {
      padding-left: 76px;
      flex: 220px;

      .img {
        width: 200px;
        vertical-align: middle;
      }

      .login-btn {
        display: inline-block;
        text-align: center;
        width: 94px;
        height: 38px;
        line-height: 38px;
        margin-left: 38px;
        background: rgba(23, 218, 163, 0.7);
        border-radius: 6px;
        font-size: 16px;
        color: #fff;
      }

      .title {
        color: #fff;
        font-size: 12px;
        display: inline-block;
        font-weight: bold;
      }
    }

    .middle-area {
      flex: 1;
    }

    .right-area {
      // width: 720px;
      text-align: right;

      .list {
        padding-right: 76px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .item {
          display: inline-block;
          height: 34px;
          line-height: 34px;
          color: #828282;
          text-align: center;
          font-size: 16px;
          cursor: pointer;
          vertical-align: top;
          font-weight: 400;
          margin-right: 35px;
          font-family: PingFangSC-Semibold, PingFang SC;

          &.router-link-active {
            color: #fff;
            border-bottom: 3px solid #FFFFFF;
            font-weight: 600;
          }
        }

        .selct-area {
          box-sizing: border-box;
          position: relative;
          display: inline-block;
          width: 60px;
          height: 22px;
          line-height: 22px;
          color: #fff;
          border-radius: 3px 3px;
          // background: rgba(255, 255, 255, 0.3);
          background: linear-gradient(180deg, #2D69FF 0%, #1438FD 100%);
          border-radius: 2px;
          font-size: 14px;
          text-align: left;
          padding-left: 6px;
          cursor: pointer;

          .text {
            display: inline-block;
            width: 100%;
            height: 100%;
            position: relative;
            padding-left: 6px;
            // background: #2D69FF;
            color: #FFFFFF;

            .icon {
              position: absolute;
              top: 7px;
              right: 12px;
              transform: translateY(-50%);
              width: 10px;
              height: 7px;
              background: url("../../assets/selectarror.png") center center no-repeat;
              background-size: contain;
              transform: rotate(90deg);
            }

            .icon.active {
              transform: rotate(180deg);
            }
          }

          .select-list {
            box-sizing: border-box;
            width: 60px;
            position: absolute;
            left: 0;
            top: 22px;
            background: rgba(255, 255, 255, 0.3);

            // background: linear-gradient(180deg, #2D69FF 0%, #1438FD 100%);
            padding-left: 6px;

            .select-item {
              box-sizing: border-box;
              font-size: 14px;
              padding-left: 6px;
            }
          }
        }
      }
    }
  }
}
</style>